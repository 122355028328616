.blog-category{
    margin-bottom:41px;
    h4{
      color: #252525;
      font-weight: 700;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 14px;
      margin-top: 28px;
  
    }
    ul{
      li{
        list-style: none;
        a{
          font-size: 16px;
          color: #636363;
          line-height: 39px;
          text-decoration: none;
          background-color: transparent;
        }
      }
    }
  }