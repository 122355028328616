.blogs__panel {
  min-width: 265px;
  max-width: 265px;
  padding: 30px 0;
    min-height: 700px;
}

.blog_page_section {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  padding: 50px 30px;
}

.blogs__page__container {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 360px);
  // justify-content: center;
  padding-left: 50px;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .blog_page_section {
    width: 100%;
    
  }
  .blogs__page__container {
        padding-left: 0px;
    }
}
@media screen and (max-width: 1090px) {
  .blog_page_section {
    flex-direction: column-reverse;
  }
  .blogs__page__container {
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 360px);
  }
  .blog_page_section {
    padding: 50px 0;
    padding-left: 40px;
  }
  .blogs__panel {
    margin-top: 30px;
  }
}

@media screen and (max-width: 835px) {
  .blog_page_section {
    padding: 0;
    margin: 0 auto;
    padding: 50px 0;
  }
  .blogs__page__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .blogs__panel{
    padding-left: 50px;
  }
}

@media screen and (max-width: 400px) {
  .blogs__panel {
    min-width: 0;
    max-width: 100%;
  }
  .blogs__panel{
    padding-left: 5px;
  }
}
