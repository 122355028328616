.Navbar-Main {
  .Navbar-Top {
    height: 35px;
    border-bottom: 1px solid #bbbbbb2e;
    background: #fff;
    width: 100%;
  }
  .Navbar-Middle {
    height: 85px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Navbar-Bottom {
    // height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #252525;
  }
}

// // .App {
// //   width: 100%;
// // }
// .Container {
// }
// .inner-container {
//   margin: 0 auto;
//   max-width: 995px;
//   padding: 20px;
// }
@media only screen and (min-width: 1400px) {
  .Navbar-Main {
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .Navbar-Main {
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Navbar-Main {
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Navbar-Main {
    .Navbar-Middle {
      height: 185px;
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .Navbar-Main {
    .Navbar-Middle {
      height: 185px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .Navbar-Main {
    .Navbar-Middle {
      height: 185px;
    }
  }
}
