.RecentPosts_Section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 55px;
  .RecentPosts_Title {
    color: #252525;
    font-weight: 500;
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 1.2;
  }
  .RecentPosts_Container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
