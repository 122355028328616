.services__container {
  min-height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px !important;
  margin-top: 50px !important;
}

.services__container {
  display: flex;
  max-width: 90vw;
  justify-content: center;
  margin: 0 auto;
}
.service {
  width: 365px;
  height: 95px;
  border: 1px solid #f3f4f0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.service:not(:first-child) {
  border-left: none;
}

.services__info {
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  color: #252525;
  margin-top: 5px;
  font-family: "Mulish", sans-serif;
  span:first-child {
    color: #252525;
    line-height: 20px;
    font-weight: 700;
  }
}

@media screen and (max-width: 995px) {
  .services__container {
    border: solid 1px #f3f4f0;
    max-width: 75%;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    .service {
      border: none;
    }
  }
}

@media screen and (max-width: 500px) {
  .services__container {
    border: solid 1px #f3f4f0;
    max-width: 85%;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
    .service {
      border: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .service {
    justify-content: flex-start;
  }
  .services__container {
    border: solid 1px #f3f4f0;
    max-width: 95%;
  }
}
