.leave_comment{
    font-family: "Mulish", sans-serif;
    padding-top: 55px;
    

    h4{
        color: #292f3b;
        font-weight: 700;
        margin-bottom: 35px;
        font-size: 24px;
        font-family: "Mulish", sans-serif;
    }

    .comment_form {
        
        input{
            width: 100%;
            height: 46px;
            font-size: 16px;
            color: #636363;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            padding-left: 20px;
            margin-bottom: 30px;
        }
        textarea{
            width: 98%;
            font-size: 16px;
            color: #636363;
            height: 116px;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            padding-left: 20px;
            padding-top: 10px;
            margin-bottom: 45px;
        }
    }
}


input{
    overflow: visible;
    margin: 0;
    font-family: inherit;
    line-height: inherit;
}

textarea{
    overflow: auto;
    margin: 0;
}

input:focus{
    outline: none;
}

textarea:focus{
    outline: none;
}

.site_btn{
    color: #ffffff;
    border: 1px solid #e7ab3c;
    background: #e7ab3c;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    padding: 13px 45px 11px;
}