.Shop_Items_Container {
  width: 70%;
  margin: 0 auto;
  padding-top: 15px;
  .Shop_Items_Options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .Shop_Options_Sorting {
      .Shop_Select {
        -webkit-tap-highlight-color: transparent;
        text-align: left !important;
        text-transform: none;
        border-radius: 0;
        border: 1px solid #ecedee;
        height: 40px;
        line-height: 35px;
        cursor: pointer;
        outline: none;
        font-size: 16px;
        color: #4c4c4c;
        user-select: none;
        white-space: nowrap;
        margin-right: 15px;
      }
    }
    .Shop_Options_Text {
      color: #636363;
      line-height: 39px;
    }
  }
  .Shop_Items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .Shop_Item {
      width: 31%;
      margin-bottom: 26px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .Shop_Items_Container {
    width: 90%;
  }
  .Shop_Items_Container {
    .Shop_Items {
      .Shop_Item {
        width: 48%;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .Shop_Items_Container {
    width: 100%;
  }
  .Shop_Items_Container {
    .Shop_Items {
      .Shop_Item {
        width: 100%;
      }
    }
  }
}
