.posted_by{
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 40px 65px;

    .posted_by_pic{
        float: left;
    }
    .posted_by_text{
        padding-left: 22px;
        display: table;
        a{
            display: block;
            h5{
                font-family: "Mulish", sans-serif;
                color: #252525;
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 13px;
            }
        }
        p{
            line-height: 30px;
            margin-bottom: 0;
        }
    }
}

.posted_by_img{
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
    border-radius: 50%;
    width: 91px;
    height: 89px;
}

a{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.posted_par{
    font-size: 18px;
    color: #636363;
    font-weight: 400;
    margin: 0 0 15px 0;
    
}

@media screen and (max-width:767px){
    .posted_by{
        padding: 40px 25px;
        text-align: center;
        .posted_by_pic{
            float: none;
            margin-bottom: 30px;
        }
        .posted_by_text{
            padding-left: 0;
        }
    }
}