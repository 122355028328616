.blog_detail_part_margin{
    .blog_detail_title{
        text-align: center;
        margin-bottom: 25px;
       // width: 550px;
        h2{
            font-family: "Mulish", sans-serif;
            color: #252525;
            font-weight: 700;
            margin-bottom: 15px;
            font-size: 36px;
        }
        p{
            font-family: "Mulish", sans-serif;
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            color: #e7ab3c;
            letter-spacing: 2px;
            line-height: 20px;
            display: inline-block;
            position: relative;
            span{
                color: #b2b2b2;
                font-size: 14px;
                font-weight: 400;
                text-transform: capitalize;
                letter-spacing: 0;
            }
        }
        p:before{
            content: " ";
            height: 1px;
            width: 125px;
            position: absolute;
            top: 8px;
            left: -175px;
            background: #ebebeb;
          }
        p:after{
            content: " ";
            height: 1px;
            width: 125px;
            position: absolute;
            top: 7px;
            right: -175px;
            background: #ebebeb;
          }
    }
    
    .blog_detail_par{
        margin: 0 0 15px 0;
    }
    
    .blog_large_pic img{
        min-width: 100%;
        min-height: 80%;
    }
    
    .blog_img{
        max-width: 100%;
      //  max-height: 60%;
        width: 100%;
        vertical-align: middle;
        border-style: none;
    }
    
    .blog_detail_description{
        margin-top: 22px;
        margin-bottom: 32px;
        p{
            font-size: 18px;
            line-height: 30px;
        }
    }
    
    .blog_desc{
        font-family: "Mulish", sans-serif;
        color: #636363;
        font-weight: 400;
        margin: 0 0 15px 0;
        span{
            color: #252525;
            text-transform: capitalize;
            font-size: 50px;
            margin: 14px 10px 0 0;
        }
    }
    
    .blog_desc::first-letter{
        color: #252525;
        text-transform: uppercase;
        font-size: 60px;
        float: left;
        margin-right: 10px;
        margin-top: 14px
    }
    
    .blog_quote{
        margin-bottom: 65px;
        p{
            font-family: "Mulish", sans-serif;
            position: relative;
            color: #252525;
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            padding-left: 32px;
            span{
                color: #161715;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }
        p:before{
            position: absolute;
            left: 0;
            top: 0;
            height: 88px;
            width: 3px;
            background: #e7ab3c;
            content: "";
        }
    
    }
    
    .blog_img_desc{
        font-family: "Mulish", sans-serif;
        color: #636363;
        font-weight: 400;
        margin: 0 0 15px 0;
        font-size: 18px;
        line-height: 30px;
    }
    
    .tag_share{
        overflow: hidden;
        border-bottom: 1px solid #ebebeb;
        margin-top: 46px;
        padding-bottom: 54px;
        margin-bottom: 40px;
        .tage_detail{
            float: left;
            ul li{
                font-family: "Mulish", sans-serif;
                list-style: none;
                display: inline-block;
                color: #171717;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
                margin-right: 16px;
                i{
                    font-size: 18px;
                    color: #e7ab3c;
                }
            }
        }
        .blog_share{
            float: right;
            span{
                font-size: 16px;
                color: #2d292f;
            }
        }
    }
    
    ul{
        padding: 0;
        margin: 0;
    }
    
    .tag_icon{
        color: #e7ab3c;
        font-size: 20px;
        transform: rotate(-90deg);
    }
    .social_links{
      display: inline-block;
      .blog_social_icons{ 
        display: flex;
        align-items: center;
        height: 100%;
        gap: 25px;
        margin-left: 25px;
        font-size: 13px;
        color: #171717;
    }
    }
    
    .blog_more_img{
        margin-bottom: 32px;
        img{
            min-width: 80%;
        }
    }
    
    .blog_row{
        display: flex;
        //flex-wrap: wrap;
    }
    
    .more_img{
      //  padding-left: 20px;
        max-width: 95%;
       // width: 50%;
    }
    
    .col-sm-4{
        width: 100%;
    }
    
    .row{
        display: flex;
        flex-wrap: wrap;
    
    }
    
    .blog_prev_next{
        padding-top: 72px;
        margin-bottom: 58px;
    
        .par_prev_text{
            display: table;
            padding-left: 20px;
        }
        .par_next_text{
            display: block;
        }
        
        .blog_prev_row{
            display: block;
            
            .prev_blog{
                float: left;
                position: relative;
                margin-top: 7px;
            }
            .par_prev_text span{
                font-size: 14px;
                color: #b2b2b2;
                letter-spacing: 2px;
            }
            .par_prev_text h5{
                width: 80%;
                font-size: 20px;
                color: #252525;
                line-height: 26px;
                font-weight: 700;
                margin-top: 10px;
                font-family: "Mulish", sans-serif;
            }
        }
        .blog_next_row{
          
            .par_next_text{
                display: table;
                padding-right: 20px;
                text-align: right;
            }
            .par_next_text span{
                font-size: 14px;
                color: #b2b2b2;
                letter-spacing: 2px;
            }
            .par_next_text h5{
                font-size: 20px;
                color: #252525;
                line-height: 26px;
                font-weight: 700;
                margin-top: 10px;
                font-family: "Mulish", sans-serif;
            }
            .next_blog{
                float: right;
                margin-top: 7px;
               .right_arrow{
                    font-size: 18px;
                    color: #b2b2b2;
               }
            }
        }
        
    }
    h5{
        margin: 0;
    }
    
    .next__blog{
        margin-left: auto;
        margin-right: auto;
    }
    
    .prev_blog_img{
        max-width: 100%;
        vertical-align: middle;
        border-style: none;
        border-radius: 50%;
        width: 70px;
        height: 70px;
    }
    .next_blog_img{
        max-width: 100%;
        vertical-align: middle;
        border-style: none;
        border-radius: 50%;
        width: 70px;
        height: 70px;
      
    }
    
    .left_arrow{
       // position: absolute;
        font-size: 18px;
        color: #b2b2b2;
    }
}


@media screen and (min-width:990px) and (max-width:1199px){
    .next__blog{
        margin-right: 20px;
        width: 400px;
        margin-top: -100px;
    }
}


@media screen and  (min-width:768px) and (max-width:990px){
    .next__blog{
        margin-right: 20px;
        margin-top: -110px;
        width: 350px;
    }
    .prev__blog{
        width: 400px;
    }
}

@media screen and (max-width:550px){
    .blog_detail_title{
        p:before{
            left: -140px;
          }
        p:after{
            right: -140px;
          }
    }
    .blog_row{
      display: block;
    }
    .col-sm-4{
        width: 100%;
    }
}

@media screen and (max-width:450px) {
    .blog_detail_title{
        p:before{
            left: -80px;
            width: 70px;
          }
        p:after{
            right: -80px;
            width: 70px;
          }
    }
}

@media screen and (max-width:340px) {
    .blog_detail_title{
        p:before{
            left: -50px;
            width: 40px;
          }
        p:after{
            right: -50px;
            width: 40px;
          }
    }
}