.Instagram_Post {
  display: flex;
  flex-shrink: 0;
  width: 16.66%;
  height: 320px;
  overflow: hidden;
  position: relative;
  .Instagram_Icon {
    display: flex;
    flex-direction: column;
      width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    visibility: hidden;
    transition: 0.2s all ease-in;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    justify-content: center;
    text-decoration: none;
    .Instagram_Text {
      color: #fff;
      margin-top: 10px;
      font-size: 18px;
    }
  }
  .Instagram_Image {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
    display: flex;
    flex-shrink: 0;
  }
  &:hover {
    .Instagram_Icon {
      visibility: visible;
      opacity: 1;
      transition: 0.2s all ease-in;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Instagram_Post {
    width: 50%;
    height: 300px;
  }
}
@media only screen and (max-width: 479px) {
  .Instagram_Post {
    width: 100%;
  }
}
