.mySwiper {
  .Carousel-Container {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0 auto !important;
    padding: 30px;
    &:hover {
      .Homepage-Slider-Card {
        .Homepage-Slider-Image {
          .Homepage-Card-Hover-Buttons {
            bottom: 4px;
          }
          .Homepage-Card-Hover-Heart {
            top: 3px;
          }
        }
      }
    }
    .Homepage-Slider-Card {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;
      .Homepage-Slider-Image {
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        img {
          width: 100%;
        }
        .Homepage-Card-Hover-Buttons {
          position: absolute;
          bottom: -80px;
          overflow: hidden;
          box-sizing: border-box;
          width: 100%;
          height: 45px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          transition: 0.5s;
          .Homepage-Card-Hover-Button-Icon {
            box-sizing: border-box;
            background: #e7ab3c;
            // padding: 8px;
            height: 45px;
            width: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            // box-shadow: 0 -2px 16px rgba(0, 0, 0, 0.15);
            cursor: pointer;
            svg {
              font-size: 1.2rem;
              color: #fff;
            }
          }
          .Homepage-Card-Hover-Button-Icon-Second {
            box-sizing: border-box;
            background: #fff;
            // padding: 8px;
            height: 45px;
            width: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 10px 30px 16px rgba(0, 0, 0, 0.15);
            cursor: pointer;
            svg {
              font-size: 1.2rem;
              color: #e7ab3c;
            }
          }
          .Homepage-Card-Hover-Button-Text {
            background: #fff;
            height: 45px;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            text-transform: capitalize;
            color: #e7ab3c;
            box-shadow: 0 30px 16px rgba(0, 0, 0, 0.164);
            cursor: pointer;
          }
        }
        .Homepage-Card-Hover-Heart {
          position: absolute;
          top: -80px;
          right: 0;
          overflow: hidden;
          box-sizing: border-box;
          width: 67px;
          height: 67px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          transition: 0.5s;
          cursor: pointer;
          svg {
            font-size: 1.2rem;
          }
        }
        .Homepage-Carousel-Sale-Icon {
          position: absolute;
          top: 20px;
          left: 0;
          overflow: hidden;
          box-sizing: border-box;
          font-size: 10px;
          color: #fff;
          background: #76bc42;
          padding: 8px 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          transition: 0.5s;
        }
      }
      .Homepage-Carousel-Slider-Conent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        .Homepage-Carousel-Slider-Title {
          font-weight: 500;
          font-size: 10px;
          text-transform: uppercase;
          color: rgb(87, 87, 87);
          margin: 0;
        }
        .Homepage-Carousel-Slider-Name {
          font-weight: 500;
          font-size: 18px;
          text-transform: capitalize;
          color: rgb(65, 65, 65);
          margin: 0;
          margin-top: 15px;
        }
        .Homepage-Carousel-Slider-Price {
          font-weight: 600;
          font-size: 20px;
          text-transform: capitalize;
          color: #e7ab3c;
          margin: 0;
          margin-top: 5px;
          .Homepage-Carousel-Slider-OldPrice {
            font-size: 14px;
            color: #b2b2b2;
            text-decoration: line-through;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
