.checkout_section{
    padding-top: 80px;
    padding-bottom: 80px;
    
}
.container{
    width: 100%;
}

.checkout_row{
    display: flex;
    flex-wrap: wrap;
    width: 78%;
    margin-left: auto;
    margin-right: auto ;
}

.checkout_content{
    margin-bottom: 50px;

}

.checkout_biiling, .checkout_order{
    //position: relative;
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
}


.checkout_content .content_btn, .checkout_content input{
    color: #252525;
    font-size: 16px;
    border: 2px solid #ebebeb;
    padding: 14px 20px;
    background: #f3f3f3;
    text-align: center;
    display: block;
}

a{
    text-decoration: none;
}

.checkout_form
{
input{
    width: 100%;
    height: 46px;
    border: 2px solid #ebebeb;
    margin-bottom: 25px;
    padding-left: 15px;
}
}
.checkout_content input{
    height: 56px !important;
}

.checkout_form h4{
    color: #252525;
    font-weight: 700;
    margin-bottom: 30px;
}

h4{
    margin: 0;
    font-family: "Muli", sans-serif;
    font-size: 24px;
    line-height: 1.2;
}

input:focus{
    outline: none;
}

.user_name{
    display: flex;
    flex-direction: row;
        .first_last_name{
          flex: 0 0 50%;
          max-width: 48%;
        }
         #last_name{
          margin-left: 25px;
        }
}

.checkout_form .create_item{
    padding-top: 15px;
}

.checkout_form .create_item label{
    position: relative;
    cursor: pointer;
    padding-left: 32px;
    margin-bottom: 0;
    font-size: 14px;
    color: #252525;
}

.checkout_form .create_item label input:checked~span{
    background: #e7ab3c;
    border-color: #e7ab3c;
}
.checkout_form .create_item label .checkmark{
    position: absolute;
    left: 0;
    top: 3px;
    height: 13px;
    width: 13px;
    border: 2px solid #b2b2b2;
    border-radius: 2px;
}


.checkout_form label{
    color: #252525;
    font-size: 16px;
    margin-bottom: 5px;
}
.checkout_form label span{
    color: #d85d5c;
}

label{
    display: inline-block;
}

.checkout_form .place_order .order_total{
    border: 2px solid #ebebeb;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 22px;
    padding-bottom: 35px;
}
.checkout_form .place_order .order_total .order_table{
    margin-bottom: 64px;
}

ul{
    padding: 0;
    margin: 0;
}

.checkout_form .place_order .order_total .order_table li:first-child{
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 20px;
    padding-top: 0;
}

.checkout_form .place_order .order_total .order_table li{
    font-family: "Muli", sans-serif;
    list-style: none;
    color: #252525;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
    padding-top: 30px;
}

.checkout_form .place_order .order_total .order_table li span{
    float: right;
}

.checkout_form .place_order .order_total .order_table li.fw_normal{
    font-weight: 400;
    text-transform: capitalize;
}

.checkout_form .place_order .order_total .order_table li.fw_normal span{
    font-weight: 700;
}


.checkout_form .place_order .order_total .order_table li.total_price span{
    color: #e7ab3c;
}

.payment_check{
    margin-bottom: 50px;
}

.checkout_form .place_order .order_total .payment_check label{
    position: relative;
    cursor: pointer;
    padding-left: 32px;
    margin-bottom: 0;
    font-size: 14px;
    color: #252525;
}

.checkout_form .place_order .order_total .payment_check label .checkmark{
    position: absolute;
    left: 0;
    top: 3px;
    height: 13px;
    width: 13px;
    border: 2px solid #b2b2b2;
    border-radius: 2px;   
}

.checkout_form .place_order .order_total .order_btn{
    text-align: center;
}

.checkout_form .place_order .order_total .order_btn .place_btn{
    padding: 13px 40px 11px;
    background: #000000;
    border-color: #000000;
}

.site_btn{
    color: #ffffff;
    border: 1px solid #e7ab3c;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

@media screen and (max-width:1246px){
    .checkout_row{
        width: 94%;
    }   
}

@media screen and (min-width:1000px) and (max-width:1199px){
    .checkout_row{
       width: 960px
    }   
}

@media screen and (max-width:991px){
    .checkout_row{
        flex-direction: column;
    }   
    .checkout_biiling, .checkout_order{
        margin: auto auto;
        width: 720px;
    }
    .user_name{
        display: block;
            .first_last_name{
              max-width: 100%;
            }
             #last_name{
              margin-left: 0;
            }
    }
}

@media screen and (min-width:555px) and (max-width:767px) {
    .checkout_biiling, .checkout_order{
        width: 540px;
    }
}

@media screen and (min-width:500px) and (max-width:555px) {
  .checkout_biiling, .checkout_order{
      max-width: 485px;
  }
}

@media screen and (min-width:465px) and (max-width:500px) {
  .checkout_biiling, .checkout_order{
      max-width: 450px;
  }
}

@media screen and (min-width:440px) and (max-width:465px) {
  .checkout_biiling, .checkout_order{
      max-width: 420px;
  }
}

@media screen and (min-width:400px) and (max-width:440px) {
  .checkout_biiling, .checkout_order{
      max-width: 400px;
  }
}

@media screen and (min-width:370px) and (max-width:400px) {
  .checkout_biiling, .checkout_order{
      max-width: 370px;
  }
}

@media screen and (min-width:326px) and (max-width:370px) {
  .checkout_biiling, .checkout_order{
      max-width: 330px;
  }
}

@media screen and (min-width:290px) and (max-width:326px) {
  .checkout_biiling, .checkout_order{
      max-width: 280px;
  }
}

@media screen and (max-width:290px){
  .checkout_biiling, .checkout_order{
    max-width: 250px;
}
}


@media screen and (max-width:300px) {
    .order_total{
        padding: 20px !important;
    }
}