.Homepage-Carousel-Section {
  margin: 50px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  box-sizing: border-box;
  overflow-y: hidden;

  .Homepage-Carousel-Banner {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 450px;
    .Homepage-Carousel-Content {
      z-index: 2;
      h2 {
        font-size: 36px;
        font-weight: 700;
        text-transform: capitalize;
        color: #fff;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
        text-align: center;
      }
      p {
        font-size: 24px;
        font-weight: 600;
        text-transform: capitalize;
        color: #fff;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
        text-align: center;
        position: relative;
        border-bottom: 4px solid #fff;
        padding-bottom: 10px;
      }
    }
    .Homepage-Banner-Carousel-Banner-Overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
  }
  .Homepage-Carousel-Container {
    width: 100%;
    .Homepage-Carousel-Tab {
      width: 100%;
      padding: 0;
      .Homepage-Carousel-Tab-Container {
        display: flex;
        justify-content: center;
        gap: 40px;
        align-items: center;
        padding: 0;
      }
      .Homepage-Carousel-Tab-Button {
        list-style: none;
        font-size: 20px;
        font-weight: 400;
        padding-bottom: 10px;
        color: #606060;
        text-transform: capitalize;
        cursor: pointer;
        border-bottom: 3px solid transparent;
        transition: 0.5s;
      }
      .Tab-Active {
        border-color: black;
        color: #000;
      }
    }
    .Homepage-Carousel-Slider-Container {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

// // .App {
// //   width: 100%;
// // }
// .Container {
// }
// .inner-container {
//   margin: 0 auto;
//   max-width: 995px;
//   padding: 20px;
// }
@media only screen and (min-width: 1400px) {
  .Homepage-Carousel-Section {
    max-width: 1380px;
    flex-direction: row;
    .Homepage-Carousel-Banner {
      width: 30%;
    }
    .Homepage-Carousel-Container {
      width: 66.67%;
      margin: 50px 0;
      .Homepage-Carousel-Tab {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .Homepage-Carousel-Section {
    max-width: 1380px;
    flex-direction: row;
    .Homepage-Carousel-Banner {
      width: 30%;
    }
    .Homepage-Carousel-Container {
      width: 66.67%;
      margin: 50px 0;
      .Homepage-Carousel-Tab {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Homepage-Carousel-Section {
    max-width: 980px;
    flex-direction: row;
    .Homepage-Carousel-Banner {
      width: 30%;
    }
    .Homepage-Carousel-Container {
      width: 66.67%;
      margin: 50px 0;
      .Homepage-Carousel-Tab {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Homepage-Carousel-Section {
    max-width: 750px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .Homepage-Carousel-Section {
    max-width: 570px;
  }
}
@media only screen and (max-width: 575px) {
  .Homepage-Carousel-Section {
    max-width: 100%;
    padding: 5px;
    .Homepage-Carousel-Container {
      .Homepage-Carousel-Tab {
        .Homepage-Carousel-Tab-Container {
          gap: 10px;
        }
        .Homepage-Carousel-Tab-Button {
          font-size: 12px;
        }
      }
    }
  }
}
