.shop__container {
  display: flex;
  font-family: "Mulish", sans-serif;
  width: 70%;
  padding-top: 30px;
  margin: 0 auto;
}

.shop__panel__container {
  min-height: 100%;
  width: 290px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  li {
    list-style-type: none;
    padding: 10px 0;
  }
}
.panel__title {
  font-weight: 700;
  font-size: 22px;
  color: rgb(37, 37, 37);
  padding: 26px 0;
  li {
    cursor: pointer;
  }
}
li {
  cursor: pointer;
}
.panel__title {
  cursor: auto;
}
.shop__checkbox {
  margin-right: 10px;
}

input[type="checkbox"] {
  accent-color: rgb(231, 171, 60);
  border-color: rgb(231, 171, 60);
  color: #fff;
}

@mixin range-slider(
  $width,
  $height,
  $input-top,
  $input-bg-color,
  $input-thumb-color,
  $float: none,
  $input-height: 16px,
  $input-border-radius: 14px
) {
  position: relative;
  width: $width;
  height: $height;
  float: $float;
  text-align: center;

  input[type="range"] {
    left: 0;
    top: $input-top;
    width: $width;
    outline: none;
    height: $input-height;
    margin: 0;
    padding: 0;
  }

  input[type="range"]::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    z-index: 1;
    outline: 0;
    -webkit-appearance: none;
    width: $input-height;
    height: 15px;
    border: none;
    border-radius: $input-border-radius;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, lighten($input-thumb-color, 60%)),
      color-stop(100%, $input-thumb-color)
    ); /* android <= 2.2 */
    background-image: -webkit-linear-gradient(
      top,
      lighten($input-thumb-color, 60%) 0,
      $input-thumb-color 100%
    ); /* older mobile safari and android > 2.2 */
    background-image: linear-gradient(
      to bottom,
      lighten($input-thumb-color, 60%) 0,
      $input-thumb-color 100%
    ); /* W3C */
  }

  input[type="range"]::-moz-range-thumb {
    pointer-events: all;
    position: relative;
    z-index: 10;
    -moz-appearance: none;
    width: $input-height;
    height: $input-height;
    border: none;
    border-radius: $input-border-radius;
    background-image: linear-gradient(
      to bottom,
      lighten($input-thumb-color, 60%) 0,
      $input-thumb-color 100%
    ); /* W3C */
  }

  input[type="range"]::-ms-thumb {
    pointer-events: all;
    position: relative;
    z-index: 10;
    -ms-appearance: none;
    width: $input-height;
    height: $input-height;
    border-radius: $input-border-radius;
    border: 0;
    background-image: linear-gradient(
      to bottom,
      lighten($input-thumb-color, 60%) 0,
      $input-thumb-color 100%
    ); /* W3C */
  }

  input[type="range"]::-moz-range-track {
    position: relative;
    z-index: -1;
    background-color: rgba(0, 0, 0, 1);
    border: 0;
  }

  input[type="range"]:last-of-type::-moz-range-track {
    -moz-appearance: none;
    background: none transparent;
    border: 0;
  }

  input[type="range"]::-moz-focus-outer {
    border: 0;
  }
}

section.range-slider {
  @include range-slider(250px, 100px, 50px, rgb(231, 171, 60), #f1f1f1);
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgb(231, 171, 60);
  border-radius: 25px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #c6c6c6;
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  width: 250px;
  position: absolute;
  background-color: #c6c6c6;
  pointer-events: none;
}

.panel__price__container {
  display: flex;
  //   justify-content: center;
  align-items: center;
}

.slider__value {
  display: flex;
  width: 52px;
  border: solid #f1f1f1 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  padding: 0 5px;
  margin: 10px 10px;
  margin-left: 0;
  padding-left: 0;
  color: #000;
  font-weight: 500;
}

.panel__dash {
  margin-right: 10px;
  color: #000;
  opacity: 0.1;
}

.panel__filter__btn {
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  background: rgb(231, 171, 60);
  padding: 10px 18px;
  border-radius: 2px;
  display: inline-block;
  text-transform: uppercase;
  border: none;
}

.panel__colors {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding-top: 50px;
  input {
    margin: 10px 0;
  }
}

#radio {
  margin-right: 10px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  outline: none;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.panel__radio__choice {
  display: flex;
  align-items: center;
}

.panel__sizes {
  display: flex;
  // justify-content: space-around;
  gap: 10px;
}
.panel__size {
  // background-color: #a5a5a5;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid rgba(0, 0, 0, 0.3) 1px;
  width: 47px;
  height: 40px;
  font-weight: bold;
  cursor: pointer;
}

.invert {
  color: #fff;
  background-color: rgb(37, 37, 37);
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid rgba(0, 0, 0, 0.3) 1px;
  width: 47px;
  height: 40px;
  font-weight: bold;
  cursor: pointer;
}

.shop__tags {
  border: solid rgba(0, 0, 0, 0.3) 1px;
  width: 80px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 70px;
  cursor: pointer;
}
.tags__container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 50px;
  padding-top: 5px;
}

.shop__tags__clicked {
  border: solid rgba(0, 0, 0, 0.3) 1px;
  width: 70px;
  font-size: 14px;
  color: #fff;
  display: flex;
  background-color: rgb(0, 0, 0, 0.8);
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  .shop__container {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
  }
  .panel__colors {
    width: 90%;
  }
}

@media screen and (max-width: 400px) {
  .shop__panel__container {
    margin-left: 0;
    width: 100%;
    padding: 5px;
  }
  .panel__colors {
    width: 80%;
  }
}
