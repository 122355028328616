.Navbar-Middle-Container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .Navbar-Logo {
    width: 215px;
  }
  .Navbar-Middle-Searchbar {
    width: 50%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e7ab3cbe;
    .Searchbar-Dropdown {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .Searchbar-Dropdown-Button {
        display: flex;
        width: 100%;
        min-height: 100%;
        margin-top: 14px;
        align-items: center;
        justify-content: space-around;
        span {
          font-size: 16px;
        }
      }
    }
    .Searchbar-Divider {
      width: 1px;
      height: 28px;
      background: #8080806f;
    }
    .Searchbar-Form {
      height: 100%;
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      .Searchbar-Form-Field {
        height: 100%;
        width: calc(100% - 50px);
        border: none;
        outline: none;
        padding: 0 22px;
        &::placeholder {
          font-size: 16px;
        }
      }
      .Searchbar-Form-Submit {
        position: relative;
        left: 7px;
        height: 42px;
        width: 42px;
        background: #e7ab3c;
        border: none;
        outline: none;
        font-size: 18px;
        color: #fff;
      }
    }
  }
  .Navbar-Button-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    .Navbar-Button-Icon {
      position: relative;
      margin: 0;
      .Navbar-Button-Icon-Icon {
        width: 22px;
      }
      .Navbar-Button-Icon-Bedge {
        position: absolute;
        width: 16px;
        height: 16px;
        background: #e7ab3c;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        font-size: 10px;
        font-weight: 600;
        color: #fff;
        top: -9px;
        right: -10px;
      }
    }
    .Navbar-Cartitems {
      cursor: pointer;
      position: relative;
      &:hover {
        .Navbar-Hover-Cart {
          display: block;
        }
      }
      // .Navbar-Hover-Cart {
      //   cursor: text;
      //   display: none;
      //   position: absolute;
      //   right: -70px;
      //   width: 350px;
      //   background: #ffffff;
      //   z-index: 99;
      //   text-align: left;
      //   padding: 30px;
      //   .Navbar-Hover-Cart-Container {
      //     list-style: none;
      //     margin: 0;
      //     padding-bottom: 35px;
      //     border-bottom: 1px solid #80808029;
      //     .Navbar-Hover-Cart-Item {
      //       display: flex;
      //       justify-content: space-between;
      //       align-items: center;
      //       margin: 13px 0;
      //       .Navbar-Hover-Cart-Item-Left {
      //         display: flex;
      //         justify-content: center;
      //         align-items: center;
      //         gap: 20px;
      //         .Navbar-Hover-Cart-Item-Image {
      //           width: 50px;
      //         }
      //         .Navbar-Hover-Cart-Item-Text {
      //           margin: 0;
      //           display: flex;
      //           flex-direction: column;
      //           font-size: 15px;
      //           font-weight: 500;
      //           .Navbar-Hover-Cart-Item-Price {
      //             font-size: 16px;
      //             color: #e7ab3c;
      //           }
      //         }
      //       }
      //       .Navbar-Hover-Cart-Item-Right {
      //         font-size: 17px;
      //         cursor: pointer;
      //       }
      //     }
      //   }
      //   .Navbar-Hover-Cart-Bottom {
      //     margin-top: 22px;
      //     .Navbar-Hover-Cart-Total {
      //       display: flex;
      //       justify-content: space-between;
      //       align-items: center;
      //       font-size: 15px;
      //       text-transform: uppercase;
      //       font-weight: 500;
      //       color: #e7ab3c;
      //     }
      //     .Navbar-Hover-Cart-Button {
      //       display: block;
      //       text-align: center;
      //       width: 100%;
      //       border: none;
      //       color: #fff;
      //       padding: 12px;
      //       font-size: 16px;
      //       font-weight: 500;
      //       text-transform: uppercase;
      //       letter-spacing: 2px;
      //       margin: 6px 0;
      //       cursor: pointer;
      //     }
      //     .View-Button {
      //       background: #252525;
      //     }
      //     .Checkout-Button {
      //       background: #e7ab3c;
      //     }
      //   }
      // }
    }
    .Navbar-Wishlist {
      cursor: pointer;
      position: relative;
      &:hover {
        .Navbar-Hover-Wishlist {
          display: block;
        }
      }
    }
    .Navbar-Cart-Amount {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .Navbar-Middle-Container {
    max-width: 1170px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .Navbar-Middle-Container {
    max-width: 1140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Navbar-Middle-Container {
    max-width: 960px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Navbar-Middle-Container {
    max-width: 720px;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    .Navbar-Logo {
      margin: 0 auto;
    }
    .Navbar-Middle-Searchbar {
      width: 100%;
      height: 60px;
      .Searchbar-Dropdown {
        display: none;
      }
      .Searchbar-Divider {
        display: none;
      }
      .Searchbar-Form {
        width: 100%;
        .Searchbar-Form-Submit {
          width: 60px;
          height: 60px;
        }
      }
    }
    .Navbar-Button-Container {
      .Navbar-Cartitems {
        .Navbar-Hover-Cart {
          right: -325px;
        }
      }
      .Navbar-Wishlist {
        .Navbar-Hover-Wishlist {
          right: -325px;
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .Navbar-Middle-Container {
    max-width: 540px;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    .Navbar-Logo {
      margin: 0 auto;
    }
    .Navbar-Middle-Searchbar {
      width: 100%;
      height: 60px;
      .Searchbar-Dropdown {
        display: none;
      }
      .Searchbar-Divider {
        display: none;
      }
      .Searchbar-Form {
        width: 100%;
        .Searchbar-Form-Submit {
          width: 60px;
          height: 60px;
        }
      }
    }
    .Navbar-Button-Container {
      .Navbar-Cartitems {
        .Navbar-Hover-Cart {
          right: -325px;
        }
      }
      .Navbar-Wishlist {
        .Navbar-Hover-Wishlist {
          right: -325px;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .Navbar-Middle-Container {
    max-width: 100%;
    padding: 0 10px;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    .Navbar-Logo {
      margin: 0 auto;
    }
    .Navbar-Middle-Searchbar {
      width: 100%;
      height: 60px;
      .Searchbar-Dropdown {
        display: none;
      }
      .Searchbar-Divider {
        display: none;
      }
      .Searchbar-Form {
        width: 100%;
        .Searchbar-Form-Submit {
          width: 60px;
          height: 60px;
        }
      }
    }
    .Navbar-Button-Container {
      .Navbar-Cartitems {
        .Navbar-Hover-Cart {
          right: -195px;
          width: 272px;
          .Navbar-Hover-Cart-Container {
            padding-bottom: 13px;
            .Navbar-Hover-Cart-Item {
              .Navbar-Hover-Cart-Item-Left {
                .Navbar-Hover-Cart-Item-Text {
                  font-size: 12px;
                  .Navbar-Hover-Cart-Item-Price {
                    font-size: 12px;
                  }
                }
              }
            }
          }
          .Navbar-Hover-Cart-Bottom {
            margin-top: 22px;
            .Navbar-Hover-Cart-Total {
              font-size: 12px;
            }
            .Navbar-Hover-Cart-Button {
              font-size: 12px;
              padding: 8px;
            }
          }
        }
      }
      .Navbar-Wishlist {
        .Navbar-Hover-Wishlist {
          right: -240px;
          width: 272px;
          .Navbar-Hover-Wishlist-Container {
            padding-bottom: 13px;
            .Navbar-Hover-Wishlist-Item {
              .Navbar-Hover-Wishlist-Item-Left {
                .Navbar-Hover-Wishlist-Item-Text {
                  font-size: 12px;
                  .Navbar-Hover-Cart-Item-Price {
                    font-size: 12px;
                  }
                }
              }
            }
          }
          .Navbar-Hover-Wishlist-Bottom {
            margin-top: 22px;
            .Navbar-Hover-Wishlist-Total {
              font-size: 12px;
            }
            .Navbar-Hover-Wishlist-Button {
              font-size: 12px;
              padding: 8px;
            }
          }
        }
      }
    }
  }
}
