*, ::after, ::before {
    box-sizing: border-box;
}


.register-form, .login-form{
    width: 555px;
    margin: auto auto;
}


.register-form h2, .login-form h2{
    color: #252525;
    font-weight: 700;
    text-align: center;
    margin-bottom: 35px;
}

.register-login-section{
    padding-top: 72px;
    padding-bottom: 80px;
}

h2{
 margin: 0;
 font-family: "Muli",sans-serif;
 line-height: 1.2;
 font-size: 36px;
}

.register-form form .group-input, .login-form form .group-input{
    margin-bottom: 25px;
}

.register-form form .group-input label, .login-form form .group-input label{
    display: block;
    font-size: 18px;
    color: #252525;
    margin-bottom: 13px;
}

.register-form form .group-input input, .login-form form .group-input input{
    border: 1px solid #ebebeb;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    padding-right: 15px;
}

input{
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
}

.register-form form .register-btn, .register-form form .login-btn, .login-form form .register-btn, .login-form form .login-btn{
    width: 100%;
    letter-spacing: 2px;
    margin-top: 5px;
}

.site-btn{
    color: #ffffff;
    background: #e7ab3c;
    border: 1px solid #e7ab3c;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 13px 45px 11px;
    cursor: pointer;
}

.register-form .switch-login, .login-form .switch-login{
    text-align: center;
    margin-top: 22px;
}

.register-form .switch-login .or-login, .login-form .switch-login .or-login{
    color: #252525;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative;
}

a{
    text-decoration: none;
    background-color: transparent;
}

.register-form .switch-login .or-login:before, .login-form .switch-login .or-login:before{
    position: absolute;
    background: #9f9f9f;
    content: "";
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
}

.login-form .group-input.gi-check{
    margin-bottom: 8px;
}

.login-form .group-input .gi-more{
    overflow: hidden;
}

.login-form .group-input .gi-more label{
    position: relative;
    cursor: pointer;
    padding-left: 28px;
    font-size: 14px;
    color: #6b6b6b;
    float: left;
}

.login-form .group-input .gi-more label .checkmark{
    position: absolute;
    left: 0;
    top: 4px;
    height: 13px;
    width: 13px;
    border: 1px solid #6c6c6c;
    border-radius: 2px;

}
.login-form .group-input .gi-more .forget-pass{
    float: right;
    line-height: 20px;
    margin-top: -2px;
    font-size: 14px;
    color: #6b6b6b;
}

// .login-form .group-input .gi-more label input{
//     position: absolute;
//     visibility: hidden;
// }


@media screen and (max-width:595px){
    .register-form, .login-form{
        width: 510px;
    }
}

@media screen and (max-width:540px){
    .register-form, .login-form{
        width: 420px;
    }
}

@media screen and (max-width:450px){
    .register-form, .login-form {
        width: 350px;
    }
}

@media screen and (max-width:378px){
    .register-form, .login-form {
        width: 300px;
    }
}

@media screen and (max-width:333px){
    .register-form, .login-form {
        width: 250px;
    }
}




