.search-form {
  form {
    width: 100%;
    display: flex;
    input {
      height: 46px;
      width: 70%;
      border: 2px solid #ececec;
      padding-left: 20px;
      font-size: 18px;
      color: #b2b2b2;
      overflow: visible;
    }
  }
  width: 100%;

  img {
    height: 16px;
    width: 16px;
  }
  button {
    color: #ffffff;
    background: #252525;
    border: 1px solid #252525;
    height: 46px;
    padding: 0 15px;
    cursor: pointer;
  }

  h4 {
    color: #252525;
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 24px;
  }
}
@media screen and (max-width: 575px) {
  .search-form {
    form {
      input {
        width: 80%;
      }
    }
  }
}
@media screen and (max-width: 440px) {
  .search-form {
    form {
      input {
        width: 70%;
      }
    }
  }
}
