.current-active-bullet {
  width: 40px !important;
  border-radius: 50px !important;

  background: #e7ab3c !important;
}

.swiper-pagination-horizontal {
  bottom: -4px !important;
}

.swiper-button-next {
  /* right: -9px; */
  /* left: auto; */
  /* top: 45% !important; */
  margin-right: -10px !important;
}
.swiper-button-prev {
  /* left: -9px; */
  /* left: auto; */
  margin-left: -10px !important;
  /* top: 45% !important; */
}
