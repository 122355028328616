.breadcrumb_text{
    font-family: "Mulish", sans-serif;
    border-bottom: 1px solid #ebebeb;
    padding-top: 15px;
    width: 77%;
    margin-left: auto;
    margin-right: auto;
}
.home__link a{
  color:#252525;
  margin-left: 10px;
}
.breadcrumb_main{
    font-size: 16px;
    color: #b2b2b2;
    text-transform: capitalize;
    padding-left: 8px;
}

.breadcrumb_text{
  display: flex;
    color: #252525;
    font-size: 16px;
}
.greater_than{
  padding-left: 8px;
  color: #b2b2b2;
}


@media screen and (max-width:1246px){
   .breadcrumb_text{
    width: 91%;
   }
}

@media screen and (min-width:1000px) and (max-width:1199px){
    .breadcrumb_text{
       width: 960px
    }   
}

@media screen and (max-width:991px){
    .breadcrumb_text{
        width: 700px
     }
}

@media screen and (min-width:555px) and (max-width:767px) {
    .breadcrumb_text{
        width: 510px;
    }
}

@media screen and (min-width:500px) and (max-width:555px) {
    .breadcrumb_text{
        max-width: 470px;
    }
  }
  
  @media screen and (min-width:465px) and (max-width:500px) {
    .breadcrumb_text{
        max-width: 425px;
    }
  }
  
  @media screen and (min-width:440px) and (max-width:465px) {
    .breadcrumb_text{
        max-width: 415px;
    }
  }

  @media screen and (min-width:400px) and (max-width:440px) {
    .breadcrumb_text{
        max-width: 375px;
    }
  }
  
  @media screen and (min-width:370px) and (max-width:400px) {
    .breadcrumb_text{
        max-width: 340px;
    }
  }
  
  @media screen and (min-width:326px) and (max-width:370px) {
    .breadcrumb_text{
        max-width: 305px;
    }
  }
  
  @media screen and (min-width:290px) and (max-width:326px) {
    .breadcrumb_text{
        max-width: 260px;
    }
  }
  
  @media screen and (max-width:290px){
    .breadcrumb_text{
      max-width: 240px;
  }
  }
  
 