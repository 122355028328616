.shopping_cart{
    padding-top: 80px;
    padding-bottom: 80px;
    
}
.container{
    font-family: "Mulish", sans-serif;;
    width: 100%;
}
.ti_close{
    cursor: pointer;
}
.cart_table{
    margin-bottom: 40px;
    table{
        width: 77%;
        margin-left: auto;
        margin-right: auto;
        min-width: 480px;
        border: 1px solid #ebebeb;
        border-collapse: collapse;

        tr{
            th{
                font-size: 16px;
                color: #252525;
                font-weight: 700;
                border-bottom: 1px solid #ebebeb;
                text-align: center;
                padding: 18px 0 19px;
                text-transform: uppercase;
            }
            th.product_name{
                text-align: left;
            }
            td{
                text-align: center;
                padding-bottom: 34px;
            }
            td.cart_pic{
                width: 21%;
            }
            td.first_row{
                padding-top: 30px;
            }
            td.cart_title{
                text-align: left;
                h5{
                    color: #252525;
                    font-size: 18px;
                    margin: 0;
                    font-weight: 400;
                    font-family: "Muli", sans-serif;
                    line-height: 1.2;
                    padding-left: 5px;
                }
            }
            td.p_price, .cart_table table {
                color: #e7ab3c;
                font-size: 16px;
                font-weight: 700;
            }
            td.p_price{
                width: 16%;
            }
            td.qua_col{
                width: 16%;
                .quantity{
                    display: flex;
                    justify-content: center;
                }
                .pro_qty{
                    width: 123px;
                    height: 46px;
                    border: 2px solid #ebebeb;
                    padding: 0 15px;
                    float: left;
                    .qtybtn{
                        color: #b2b2b2;
                        float: left;
                        line-height: 38px;
                        cursor: pointer;
                        width: 18px;
                    }
                    input{
                        text-align: center;
                        width: 52px;
                        font-size: 14px;
                        font-weight: 700;
                        border: none;
                        color: #4c4c4c;
                        line-height: 40px;
                        float: left;
                    }
                }
                .qtybtn.dec{
                    font-size: 30px;
                }
                .qtybtn.inc{
                    font-size: 24px;
                }
            }
            td.total_price{
                width: 12%;
                color: #e7ab3c;
                font-size: 16px;
                font-weight: 700;
            }
            td.close_td{
                font-size: 16px;
                color: #252525;
                width: 8%;
                padding-top: 30px;
            }
        }
    }
}

[class^="ti_"], [class*=" ti_"]{
    font-family: 'themify';
    speak-as: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.sweter_img{
    // width: 171px;
    // height: 171px;
    max-width: 171px;
    max-height: 171px;
    min-width: 171px;
    min-height: 171px;
    vertical-align: middle;
    border-style: none;
}

button, input{
    overflow: visible;
    margin: 0;
    font-family: inherit;
}

// --------------- Cart Price -----------------

.price_container{
    display: flex;
    flex-wrap: wrap;
    width: 77%;
    margin-left: auto;
    margin-right: auto ;
}

.cart_btn_discount{
    width: 33%;
}

.cart_buttons{
    margin-bottom: 34px;
    .continue_shop{
        color: #b2b2b2;
        border: 2px solid #ebebeb;
        background: #fff;
        padding: 12px 20px 12px 20px;
        margin-bottom: 10px;
        margin-right: 15px;
    }
    .update_cart{
        color: #252525;
        background: #f3f3f3;
        border: 2px solid #ebebeb;
        padding: 12px 20px 12px 20px;
      //  margin-left: 15px;
    }
}

.primary_btn{
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

a{
    text-decoration: none;
}

.discount{
    margin-bottom: 30px;
    h6{
        color: #232530;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 16px;
        font-size: 16px;
    }
    .discount_form{
        position: relative;
        input{
            width: 100%;
            height: 46px;
            border: 2px solid #ebebeb;
            color: #b2b2b2;
            font-size: 14px;
            padding-left: 20px;
        }
        .discount_btn{
            position: absolute;
            right: 0;
            top: 0;
            color: #252525;
            background: transparent;
            border: 1px solid transparent;
            padding: 13px 25px 10px 35px;
        }
    }
}

input{
    overflow: hidden;
    margin: 0;
    font-family: inherit;
    line-height: inherit;
}

.site_btn{
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}


.proceed_checkout {
    width: 31%;
    margin-left: auto;
    ul{
        border: 2px solid #ebebeb;
        background: #f3f3f3;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 16px;
        padding-bottom: 20px;
        li.subtotal{
            font-weight: 400;
            text-transform: capitalize;
            border-bottom: 1px solid #fff;
            padding-bottom: 14px;
            span{
                font-weight: 700;
            }
        }
        li.cart_total {
            padding-top: 10px;
            span{
                color: #e7ab3c;
            }
            font-weight: 700;
            text-transform: uppercase;

        }
        li{
            list-style: none;
            font-size: 16px;
            color: #252525;
            overflow: hidden;
            span{
                float: right;
            }
        }
    }
    .proceed_btn{
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        background: #252525;
        text-transform: uppercase;
        padding: 15px 25px 14px 25px;
        display: block;
        text-align: center;
    }
}
ul{
    margin: 0;
}




@media screen and (max-width:1246px){
    .cart_table{
        table{
            width: 91%;
        }
    }   
    .price_container{
        width: 91%;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px){
    .cart_table{
        table{
            width: 960px;
        }
    }   
    .price_container{
        width: 960px;
    }
    
    .cart_buttons{
        .update_cart{
            margin-left: 0;
        }
    }
}

@media screen and (max-width:988px){
    .cart_table{
        width: 770px;
        margin-left: auto ;
        margin-right: auto;
    }   
    .price_container{
        width: 700px;
    }
    .cart_btn_discount{
        width: 100%;
    }
    .proceed_checkout{
        width: 100%
    }
}

@media screen and (max-width:767px){
    .cart_table{
        table{
            tr{
                th{
                    font-size: 12px;
                }
            }
        }
    }
    .sweter_img{
        max-width: 107px;
        max-height: 107px;
        min-width: 107px;
        min-height: 107px;
    }
}

@media screen and (min-width:555px) and (max-width:767px) {
    .cart_table{
        width: 560px;
    }   
    .price_container{
        width: 520px;
    }
}


@media screen and (min-width:500px) and (max-width:555px) {
    .cart_table{
        width: 500px;
    }   
    .price_container{
        width: 485px;
    }   
}

@media screen and (max-width: 500px){
    .sweter_img{
        max-width: 90px;
        max-height: 90px;
        min-width: 90px;
        min-height: 90px;
    }
    .cart_table{
        overflow: auto;
     }
}

@media screen and (min-width:465px) and (max-width:500px) {
    .cart_table{
        width: 420px;
     }
    .price_container{
        width: 450px;
    } 
  }

  @media screen and (min-width:440px) and (max-width:465px) {
    .cart_table{
        width: 390px;
     }
    .price_container{
        width: 400px;
    } 
  }

  @media screen and (min-width:400px) and (max-width:440px) {
    .cart_table{
        width: 370px;
     }
    .price_container{
        width: 370px;
    }
  }

  @media screen and (min-width:370px) and (max-width:400px) {
    .cart_table{
       width: 340px;
     }
    .price_container{
        width: 350px;
    }
  }
  
  @media screen and (min-width:330px) and (max-width:370px) {
    .cart_table{
        width: 320px;
     }
    .price_container{
        width: 320px;
    }
    
  }

  @media screen and (min-width:290px) and (max-width:330px) {
    .cart_table{
        width: 265px;
     }
    .price_container{
        width: 270px;
    }
  }
  
  @media screen and (max-width:290px){
    .cart_table{
       width: 250px;
     }
    .price_container{
        width: 240px;
    }
  }