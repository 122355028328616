@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");
* {
  padding: 0;
  margin: 0;
}

.categories__section {
  display: flex;
  width: 91%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
}

.category__image {
  max-width: 100%;
  max-height: 100%;
}

.single-banner {
  display: flex;
  min-width: 267px;
  min-height: 150px;
  position: relative;
  vertical-align: middle;
}
.single-banner:hover::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.single-banner::before {
  position: absolute;
  left: 20px;
  top: 30px;
  border: 13px solid #ffffff;
  content: "";
  opacity: 0.3;
  width: calc(100% - 70px);
  height: calc(100% - 80px);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.single-banner .inner-text {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.single-banner .inner-text h4 {
  color: #252525;
  font-weight: 700;
  padding: 11px 28px;
  background: #ffffff;
  border-radius: 2px;
}

.inner-text h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: "Muli", sans-serif;
}


@media screen and (max-width: 975px) {
    .categories__section{
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin-top: 10px;
    }
    .single-banner{
        max-width:95vw;
        width: 100%;
    }
    .category__image {
        min-width: 100%;
        min-height: 100%;
      }
}
    
