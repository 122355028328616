@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
$muli-font: "Mulish", sans-serif;

.Info_Container {
  display: flex;
  min-height: 200px;
  justify-content: space-between;
  font-family: $muli-font;
  a {
    color: white;
    cursor: pointer;
  }
  .ContactInfo {
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      align-items: center;
      .icons {
        margin-right: 30px;
      }
    }

    p {
      color: #b2b2b2;
    }
  }
}
.Footer_Container {
  overflow: hidden;
  background-color: #191919;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 140px 10vw 0;
  margin-bottom: 0;
  h1 {
    margin: 0px;
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff !important;
  }

  .newsletter_form {
    input {
      color: #b2b2b2;
      background: #303030;
      width: 60%;
      height: 46px;
      font-size: 16px;
      border: none;
      padding-left: 20px;
      overflow: visible;
    }

    input:focus {
      outline: none;
    }

    button {
      display: inline-block;
      color: #ffffff;
      background: #e7ab3c;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      border: 1px solid #e7ab3c;
      padding: 13px 20px 12px;
      cursor: pointer;
      height: 46.5px;
    }
  }
}
.Footer_Copyright {
  padding-top: 100px;
  padding-bottom: 60px;
  a {
    color: inherit;
  }
  a:hover {
    color: #e7ab3c;
  }

  float: left;
  font-size: 16px;
  color: #b2b2b2;
  img {
    float: right;
  }
}
.Footer_Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    height: 25px;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      padding: 10px;
      margin-left: 0;
      padding-left: 0;
      color: #b2b2b2;
    }
  }
}

.Footer_icons {
  margin-right: 20px;
  border-radius: 50%;
  transform: scale(1.3);

  cursor: pointer;
}
a:hover {
  color: #e7ab3c;
  transition: 0.3s;
}
.Footer_icons:hover {
  color: rgb(21, 151, 229);
  animation: grow;
  animation-duration: 1s;
  animation-direction: alternate;
}
.Logos_Container {
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .Footer_logo {
      width: 210px;
    }
    h1 {
      margin: 0;
    }
  }
}
.Logos {
  display: flex;
  justify-content: flex-start;
  a {
    background-color: #434445;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 10px;
    margin-right: 10px;
  }

  a:hover {
    background-color: #e7ab3c;
    transition: 0.3s;
  }

  img {
    height: auto;
    width: auto;
  }
}
@keyframes grow {
  0% {
    color: white;
    transform: scale(1.5);
  }
  100% {
    color: rgb(21, 151, 229);
    transform: scale(1.5);
  }
}
// ----------------- Media Query ------------------- //

@media only screen and (max-width: 1280px) {
  .Info_Container {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  .Footer_Container {
    padding: 60px 100px;

    .Footer_Copyright {
      text-align: center;
      align-items: center;
    }
  }
  .Logos_Container {
    .title {
      margin-bottom: 20px;
      a {
        font-size: inherit;
        margin-bottom: 0 !important;
      }
    }
  }
  .Footer_Column {
    margin-bottom: 30px;
    h1 {
      margin: 40px 0;
    }
    a {
      margin: 5px 0;
    }
  }
}
@media only screen and (max-width: 1039px) {
  .Info_Container {
    .ContactInfo {
      a {
        .icons {
          margin-right: 10px;
        }
      }
    }
  }
  .Footer_Container {
    padding: 80px;
  }
  .Logos_Container {
    .title {
      .Footer_logo {
        height: 35px;
        width: 210px;
      }
    }
  }

  .Footer_Copyright {
    padding-top: 70px;
    padding-bottom: 0px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    img {
      width: 300px;
      height: auto;
      margin-left: 50px;
    }
  }
  .Footer_Column {
    h1 {
      height: 0px;
    }
  }
}
@media only screen and (max-width: 821px) {
  .Footer_Container {
    padding: 60px;
  }
  .Footer_icons {
    transform: scale(0.8);
    margin-right: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .Info_Container {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  .Footer_Container {
    padding: 60px 20px;
  }
  .Logos_Container {
    .title {
      margin-bottom: 20px;
      a {
        font-size: inherit;
        margin-bottom: 0 !important;
      }
    }
  }
  .Footer_Column {
    margin-bottom: 30px;
    h1 {
      margin: 40px 0;
    }
    a {
      margin: 5px 0;
    }
  }

  .Footer_Copyright {
    float: none;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}
