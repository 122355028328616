.deals__container {
  min-height: 65vh;
  background-color: #f4f1e9;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/deals.webp");

  margin: 0 auto;
  background-size: cover;
  width: 90vw;
  display: flex;
  margin-top: 50px;
  align-items: center;
}
.deals__details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 50%;
}
.clock__container {
  display: flex;
  gap: 40px;
  height: 100%;
  align-items: center;
}
.clock {
  background-color: white;
  border-radius: 7px;
  width: 74px;
  height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #e7ab3c;
  font-weight: bold;
  font-size: 36px;
  font-family: "Mulish", sans-serif;
  p {
    font-size: 16px;
    font-weight: 400;
    color: #b2b2b2;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
.deals__title {
  color: #252525;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 50px;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    width: 80px;
    height: 3px;
    background: #e7ab3c;
    content: "";
    margin: 0 auto;
  }
}

.deals__description {
  width: 60%;
  text-align: center;
  font-size: 16px;
  font-family: "Muli", sans-serif;
  color: #636363;
  font-weight: 400;
  line-height: 26px;
}

.deals__price {
  font-size: 16px;
  font-weight: 400;
  color: #636363;

  font-family: "Mulish", sans-serif;
  span {
    font-size: 24px;
    font-weight: 700;
    color: #e7ab3c;
  }
  padding-top: 20px;
  padding-bottom: 20px;
}

.deals__button {
  font-size: 14px;
  font-weight: 700;
  padding: 12px 30px;
  cursor: pointer;
  color: #fff;
  background: #e7ab3c;
  text-transform: uppercase;
  border: none;
  margin-top: 50px;
}

@media screen and (max-width: 960px) {
  .deals__container {
    width: 100vw;
  }
  .deals__details {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .clock__container {
    gap: 15px;
  }
  .clock {
    width: 60px;
    height: 86px;
  }
}

@media screen and (max-width: 340px) {
  .deals__title {
    font-size: 30px;
  }
  .clock {
    width: 55px;
    height: 80px;
  }
}
