.blog-tags{
    .tag-items {
      a{
      display: inline-block;
      font-size: 16px;
      color: #636363;
      padding: 5px 15px;
      border: 1px solid #ebebeb;
      margin-right: 5px;
      margin-bottom: 9px;
      border-radius: 2px;
      text-decoration: none;
      background-color: transparent;
    }
  }
    h4{
      color: #252525;
      font-weight: 700;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 14px;
  
    }
  
  }