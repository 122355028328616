.Navbar-Top-Container {
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .Navbar-Top-Left {
    min-width: 370px;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .Navbar-Devider {
      height: 100%;
      width: 1px;
      background: #bbbbbb2e;
    }
    .Navbar-Contact-Icon {
      display: flex;
      align-items: center;
      gap: 12px;
      height: 100%;
    }
  }
  .Navbar-Top-Right {
    min-width: 450px;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .Navbar-Devider {
      height: 100%;
      width: 1px;
      background: #bbbbbb2e;
    }
    .Navbar-Social-Icons {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 12px;
      font-size: 18px;
    }
    .Navbar-Language-Change {
      display: flex;
      gap: 12px;
      height: 100%;
      align-items: center;
      min-width: 80px;
      img {
        width: 20px;
      }
    }
    .Navbar-Login {
      display: flex;
      gap: 12px;
      align-items: center;
      height: 100%;
      .Navbar-Login-Icon {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .Navbar-Top-Container {
    max-width: 1170px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .Navbar-Top-Container {
    max-width: 1140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Navbar-Top-Container {
    max-width: 960px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Navbar-Top-Container {
    max-width: 720px;
    justify-content: flex-end;
    padding: 0 10px;
    .Navbar-Top-Left {
      display: none;
    }
    .Navbar-Top-Right {
      justify-content: flex-end;
      gap: 20px;
      .Navbar-Social-Icons {
        display: none;
      }
      .Navbar-Language-Change {
        .Navbar-Language-Change-Text {
          font-size: 14px;
        }
        .Navbar-Language-Change-Image {
          width: 23px;
        }
      }
      .Navbar-Login {
        .Navbar-Login-Text {
          font-size: 14px;
        }
        .Navbar-Login-Icon {
          font-size: 18px;
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .Navbar-Top-Container {
    max-width: 540px;
    justify-content: flex-end;
    padding: 0 10px;
    .Navbar-Top-Left {
      display: none;
    }
    .Navbar-Top-Right {
      justify-content: flex-end;
      gap: 20px;
      .Navbar-Social-Icons {
        display: none;
      }
      .Navbar-Language-Change {
        .Navbar-Language-Change-Text {
          font-size: 14px;
        }
        .Navbar-Language-Change-Image {
          width: 23px;
        }
      }
      .Navbar-Login {
        .Navbar-Login-Text {
          font-size: 14px;
        }
        .Navbar-Login-Icon {
          font-size: 18px;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .Navbar-Top-Container {
    max-width: 100%;
    padding: 0 10px;
    justify-content: flex-end;
    .Navbar-Top-Left {
      display: none;
    }
    .Navbar-Top-Right {
      justify-content: flex-end;
      gap: 20px;
      .Navbar-Social-Icons {
        display: none;
      }
      .Navbar-Language-Change {
        .Navbar-Language-Change-Text {
          font-size: 14px;
        }
        .Navbar-Language-Change-Image {
          width: 23px;
        }
      }
      .Navbar-Login {
        .Navbar-Login-Text {
          font-size: 14px;
        }
        .Navbar-Login-Icon {
          font-size: 18px;
        }
      }
    }
  }
}
