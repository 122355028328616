.faq_container {
  width: 1800px;
  margin: 70px auto;
  padding: 0 10px;
  max-width: 1180px;
}

.title {
  font-size: 22px;
  background-color: transparent;
  color: #252525;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 14px 0px;
  cursor: pointer;
  p {
    display: flex;
    align-items: center;
  }
}
.plus-minus {
  font-family: "Muli", sans-serif;
  font-size: 22px;
  background: #efeff0;
  left: 0;
  top: 0;
  line-height: 25px;
  padding: 3px 5px;
  margin-right: 15px;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .plus-minus:active , .plus-minus:hover {
//   background: rgb(231, 171, 60);
// }

//  .plus-minus.collapsed,
//  .plus-minus.collapsed:hover {
//   background: rgb(231, 171, 60);
// }

.content {
  background-color: transparent;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  margin: 0 0 15px 0;
  color: #636363;
  opacity: 0.9;
  line-height: 25px;
  padding: 1px 15px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.orange {
  background-color: #e7ab3c;
  color: white;
}
@media screen and (max-width: 1199px) {
  .faq_container {
    max-width: 980px;
  }
}

@media screen and (max-width: 991px) {
  .faq_container {
    max-width: 700px;
  }
}

@media screen and (max-width: 767px) {
  .faq_container {
    max-width: 550px;
  }
}

@media screen and (max-width: 580px) {
  .faq_container {
    max-width: 500px;
  }
}

@media screen and (max-width: 486px) {
  .faq_container {
    max-width: 450px;
  }
}

@media screen and (max-width: 440px) {
  .faq_container {
    max-width: 400px;
  }
}

@media screen and (max-width: 396px) {
  .faq_container {
    max-width: 340px;
  }
}

@media screen and (max-width: 335px) {
  .faq_container {
    max-width: 265px;
  }
}
