.Navbar-Hover-Wishlist {
  cursor: text;
  display: none;
  position: absolute;
  right: -70px;
  width: 350px;
  background: #ffffff;
  z-index: 99;
  text-align: left;
  padding: 30px;
  .Navbar-Hover-Wishlist-Container {
    list-style: none;
    margin: 0;
    padding-bottom: 35px;
    border-bottom: 1px solid #80808029;
    .Navbar-Hover-Wishlist-Item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 13px 0;
      .Navbar-Hover-Wishlist-Item-Left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .Navbar-Hover-Wishlist-Item-Image {
          width: 50px;
        }
        .Navbar-Hover-Wishlist-Item-Text {
          margin: 0;
          display: flex;
          flex-direction: column;
          font-size: 15px;
          font-weight: 500;
          .Navbar-Hover-Wishlist-Item-Price {
            font-size: 16px;
            color: #e7ab3c;
            .Item-OldPrice {
              color: #5a5a5a;
              text-decoration: line-through;
              font-weight: 300;
              font-size: 13px;
              margin-right: 8px;
            }
          }
        }
      }
      .Navbar-Hover-Wishlist-Item-Right {
        font-size: 17px;
        cursor: pointer;
      }
    }
  }
  .Navbar-Hover-Wishlist-Bottom {
    margin-top: 22px;
    .Navbar-Hover-Wishlist-Total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 500;
      color: #e7ab3c;
    }
    .Navbar-Hover-Wishlist-Button {
      display: block;
      text-align: center;
      width: 100%;
      border: none;
      color: #fff;
      padding: 12px;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin: 6px 0;
      cursor: pointer;
    }
    .View-Button {
      background: #252525;
    }
    .Cart-Button {
      background: #e7ab3c;
    }
  }
}

@media only screen and (max-width: 575px) {
}
