@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;700&display=swap");
.header__container {
  min-height: 85vh;
  background-color: #f7f8f1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
}

.header__info {
  font-family: "Mulish", sans-serif;
  width: 70%;
  height: 200px;
  margin-top: -70px;
  .header__category {
    color: #e7ab3c;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-left: 7px;
  }
  .header__title {
    color: #252525;
    font-size: 72px;
    font-weight: 700;
  }
  .header__desc {
    font-size: 16px;
    color: #636363;
    font-weight: 400;
    line-height: 26px;
    padding-left: 7px;
  }
}

.header__btn {
  margin-top: 50px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 30px;
  color: #ffffff;
  background: #e7ab3c;
  margin-left: 7px;
  text-transform: uppercase;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: #fff;
    }
  }
}

.circle {
  width: 140px;
  height: 140px;
  background-color: #e7ab3c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  font-family: "Mulish", sans-serif;
  color: white;
  position: absolute;
  left: 47%;
  top: 22%;
  border: #e7ab3c solid 6px;
}
.inner__cirlce {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 98%;
  border-style: dashed;
  border-width: 2px;
  border-color: white;
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .header__info {
    font-family: "Mulish", sans-serif;
    width: 85%;
  }
}

@media screen and (max-width: 1000px) {
  .header__container {
    justify-content: flex-start;
    padding-left: 50px;
  }
  .header__info {
    font-family: "Mulish", sans-serif;
    width: 45%;
  }
}

@media screen and (max-width: 750px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 30px !important;
  }
  .header__container {
    min-height: 45vh;
  }
  .header__container {
    background-image: none !important;
    width: 100%;
    padding: 0;
    padding-left: 80px;
    padding-top: 50px;
  }
  .header__info {
    width: 70%;
    justify-content: center;
    align-items: center;
  }
  .circle {
    display: none;
  }
  .header__title {
    font-size: 40px !important;
    margin-left: 5px;
  }
}

@media screen and (max-width: 355px) {
  .header__container {
    padding: 0;
    padding-left: 40px;
  }
  .header__container {
    padding-bottom: 50px !important;
  }
  .header__btn {
    margin-top: 50px;
    margin-bottom: 50px !important;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: black !important;
  font-size: 28px !important;
}

.swiper-button-next:hover::after,
.swiper-button-prev:hover::after {
  color: #e7ab3c !important;
}
