.blog_detail{
    padding-top: 80px;
    padding-bottom: 80px;
}

.blog_detail_margin{
    width: 77%;
    margin-left: auto;
    margin-right: auto;
}


@media screen and (max-width:1246px){
    .blog_detail_margin{
        width: 91%;
    }   
}

@media screen and (min-width:1000px) and (max-width:1199px){
    .blog_detail_margin{
       width: 960px
    }   
}

@media screen and (max-width:991px){
   .blog_detail_margin{
      width: 720px;
   }
}

@media screen and (min-width:555px) and (max-width:767px) {
    .blog_detail_margin{
        width: 520px;
    }
}

@media screen and (min-width:500px) and (max-width:555px) {
    .blog_detail_margin{
        max-width: 480px;
    }
  }
  
  @media screen and (min-width:465px) and (max-width:500px) {
    .blog_detail_margin{
        width: 400px;
    }
  }
  
  @media screen and (min-width:443px) and (max-width:465px) {
    .blog_detail_margin{
        max-width: 400px;
    }
  }

  @media screen and (min-width:415px) and (max-width:443px) {
        .blog_detail_margin{
            width: 340px;
        }
  }
  
  @media screen and (min-width:326px) and (max-width:415px) {
    .blog_detail_margin{
        max-width: 310px;
    }
  }
  
  @media screen and (min-width:290px) and (max-width:326px) {
    .blog_detail_margin{
        max-width: 265px;
    }
  }
  
  @media screen and (max-width:290px){
    .blog_detail_margin{
      max-width: 230px;
  }
  }