.Navbar-Bottom-Bottom-Mobile-Menu {
  height: 0;
}
.Navbar-Bottom-Container {
  margin: 0 auto;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  width: 100%;
  .Navbar-Bottom-Top-Menu {
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    .Navbar-Bottom-Top-Menu-Left {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 60px;
      padding: 8px 30px 10px;
      min-width: 300px;
      background: #3b3b3b;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        .Navbar-Bottom-Top-Menu-Category {
          .Navbar-Category-Lists {
            display: block;
            top: 43px;
            z-index: 5;
            opacity: 1;
          }
        }
      }

      .Navbar-Bottom-Top-Menu-Category {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
        .Navbar-Category-Lists {
          display: none;
          position: absolute;
          background: #fff;
          color: #252525;
          padding: 20px;
          width: 100%;
          top: 40px;
          left: 0;
          opacity: 0;
          cursor: text;

          .Navbar-Category-List-Container {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 14px 34px 20px;
            .Navbar-Category-List-Item {
              text-transform: capitalize;
              font-size: 15px;
              font-weight: 600;
              transition: 0.4s;
              color: #3b3b3b;
              cursor: pointer;
              &:hover {
                color: #e7ab3c;
              }
            }
          }
        }
      }
    }
    .Navbar-Bottom-Top-Menu-Right {
      // padding: 14px 84px 14px 18px;
      .Navbar-Bottom-Main-Menu {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0px;
        list-style: none;
        margin: 0;
        .Navbar-Bottom-Submenu-Container {
          position: relative;
          padding: 8px 30px 10px;
          text-align: center;
          border-right: 1px solid #3b3b3b;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          transition: 0.3s;
          .Navbar-Bottom-Menu-Submenu {
            position: absolute;
            background: #252525;
            padding: 10px 20px;
            width: 100%;
            min-width: fit-content;
            z-index: 2;
            display: none;
            right: 0;
            transition: 0.5s;
            top: 43px;
            min-width: 180px;
            list-style: none;
            text-align: left;
            cursor: text;
            &:hover {
              display: block;
            }
            .Navbar-Bottom-Menu-Submenu-Item {
              margin-top: 15px;
              a {
                color: #fff;
                text-decoration: none;
                &:hover {
                  color: #e7ab3c;
                  cursor: pointer;
                }
              }
            }
          }
          &:hover {
            background: #e7ab3c;
            .Navbar-Bottom-Menu-Submenu {
              display: block;
            }
          }
        }
        .Navbar-Bottom-Menu-Item {
          padding: 11px 30px 11px;
          text-align: center;
          border-right: 1px solid #3b3b3b;

          color: #fff;
          text-decoration: none;
          font-size: 16px;
          font-weight: 600;

          &:hover {
            background: #e7ab3c;
            cursor: pointer;
          }
        }
        .Active {
          background: #e7ab3c;
        }
      }
    }
  }
  .Navbar-Bottom-Bottom-Menu {
    padding: 6px 20px;
    background: #4c4c4c;

    .Navbar-Bottom-Bottom-Menu-Button {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 30px;
      background: #252525;
      padding: 8px 12px;
      border-radius: 5px;
      width: 120px;
      position: relative;
      margin-left: auto;
      cursor: pointer;
    }
    .Navbar-Bottom-Bottom-Mobile-Menu {
      display: flex;
      flex-direction: column;
      gap: 22px;
      list-style: none;
      pointer-events: none;
      margin-top: 30px;
      .Navbar-Bottom-Bottom-Mobile-Menu-Item {
        color: #fff;
        font-size: 15px;
        text-decoration: none;
      }
      .Navbar-Bottom-Mobile-Submenu-Container {
        color: #fff;
        font-size: 15px;
        position: relative;
        .Navbar-Bottom-Mobile-Submenu {
          list-style: none;
          margin-left: 20px;
          margin-top: 15px;

          .Navbar-Bottom-Mobile-Submenu-Item {
            margin-top: 8px;
            a {
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .Navbar-Bottom-Container {
    max-width: 1170px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .Navbar-Bottom-Container {
    max-width: 1140px;
    .Navbar-Bottom-Top-Menu {
      .Navbar-Bottom-Top-Menu-Left {
        padding: 14px;
        min-width: 270px;
        gap: 60px;
        .Navbar-Bottom-Top-Menu-Category {
          font-size: 16px;
        }
      }
      .Navbar-Bottom-Top-Menu-Right {
        // padding: 14px 84px 14px 18px;
        .Navbar-Bottom-Main-Menu {
          .Navbar-Bottom-Submenu-Container {
            padding: 14px;
          }
          .Navbar-Bottom-Menu-Item {
            padding: 14px;
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Navbar-Bottom-Container {
    max-width: 960px;
    .Navbar-Bottom-Top-Menu {
      .Navbar-Bottom-Top-Menu-Left {
        padding: 14px;
        min-width: 270px;
        gap: 60px;
        .Navbar-Bottom-Top-Menu-Category {
          font-size: 16px;
        }
      }
      .Navbar-Bottom-Top-Menu-Right {
        // padding: 14px 84px 14px 18px;
        .Navbar-Bottom-Main-Menu {
          .Navbar-Bottom-Submenu-Container {
            padding: 14px;
          }
          .Navbar-Bottom-Menu-Item {
            a {
              font-size: 16px;
            }
            padding: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Navbar-Bottom-Container {
    max-width: 720px;
    .Navbar-Bottom-Top-Menu {
      display: none;
    }
    .Navbar-Bottom-Bottom-Menu {
      padding: 8px;
      margin: 10px;
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .Navbar-Bottom-Container {
    max-width: 540px;
    .Navbar-Bottom-Top-Menu {
      display: none;
    }
    .Navbar-Bottom-Bottom-Menu {
      padding: 8px;
      margin: 10px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .Navbar-Bottom-Container {
    max-width: 100%;
    padding: 0 10px;
    .Navbar-Bottom-Top-Menu {
      display: none;
    }
    .Navbar-Bottom-Bottom-Menu {
      padding: 8px;
      margin: 10px;
    }
  }
}
