@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
$muli-font: 'Mulish', sans-serif;

.Contact_Info_Container{
  display: flex;
  min-height: 200px;
  justify-content: space-between;
  font-family:$muli-font;
  a{
      color: black;
      cursor: pointer;
  }
  .FormContactInfo{
      display: flex;
      flex-direction: column;
      width:45%;
      padding-right: 6%;
      padding-left:3%;
 
      a{
          display: flex;
          align-items: center;
          .icons{
              margin-right: 30px;
          }
      }

      p{
        font-size: 16px;
        font-family: $muli-font;
        color: #636363;
        font-weight: 400;
        line-height: 26px;
        margin-top:30px;
        
      }
  }
}
.Contact_Container{
overflow: hidden;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 100px 10vw 80px;
  margin-bottom: 40px;
  h1{
      margin: 0px;
      font-size:1.5rem;
      font-weight: bold;
  }


  .Form_Card{
    display: flex;
    flex-direction: row;
    background: #fff;
    margin-top:11px;
    //margin-bottom: 11px;
    -webkit-box-shadow: 0 8px 35px 10px #e8e8e8;
    box-shadow: 0 8px 35px 10px #e8e8e8;
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;

    span{
      color: #b2b2b2;
      font-size: 16px;
    }

    p{
      font-size: 18px;
      color: #252525;
      margin-bottom: 0;

      
    }

    img{
      width: 40px;
      height: auto;
      margin-left: 15px;
    }

    .Form_Card_Text{
      padding-left: 30px;
    }
  }




  .contact_form{

    input{
      width: 80%;
      font-size: 16px;
      color: #636363;
      height: 50px;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      padding-left: 15px;
      margin-bottom: 30px

    }

    textarea{
      width: 100%;
      resize: none;
      font-size: 16px;
      color: #636363;
      height: 150px;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      padding-left: 15px;
      padding-right: 40px;
      padding-top: 10px;
      margin-bottom: 15px;
      font-family: $muli-font;
    }

  input:focus{
      outline: none;
  }


    button{
      display: inline-block;
      width:180px;
      color: #ffffff;
      background: #e7ab3c;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      border: 1px solid #e7ab3c;
      padding: 13px 20px 12px;
      cursor: pointer;
      height: 46.5px;
      margin-top:35px;

    }
  }


  .form_name_mail{
display: flex;
flex-direction: row;
    .contact_form_col{
      flex: 0 0 50%;
      max-width: 50%;

   
    }
     #form-mail{
      margin-left: 20px;
    }
  }
}

.Contact_Column{
  display: flex;
  flex-direction: column;
 justify-content: left;
  margin-left:30px;
  width:40%;
  padding-right:2%;

  p{
    font-size: 16px;
    font-family: $muli-font;
    color: #636363;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 15px 0;
  }


  h1{
    height:30px;
    padding-bottom: 15px;
  }

}


a:hover{
  color:#e7ab3c;
  transition:0.3s;

}



@keyframes grow {
  0%{   color: white; transform: scale(1.5);}
  100%{   color: rgb(21, 151, 229); transform: scale(1.5);}
}
// ----------------- Media Query ------------------- //


@media only screen and (max-width: 992px) {



  .Contact_Info_Container{
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    width: 100%;

    .FormContactInfo{
      width: 100%;
      
    }

}

.Contact_Container{
    padding: 60px 100px;

    .contact_form{
      textarea{
        padding-right:0;
      }
    }


}

.Contact_Column{
    margin-bottom: 30px;
    width:100%;
    
    h1{
        margin: 40px 0;
    }
    a{
        margin: 5px 0;
    }
}

.contact_form{
  .form_name_mail{
    display: flex;
    flex-direction: column;
        .contact_form_col{
          width: 100%;
          margin:0;
          max-width:100%;
          min-height: 50px;
          margin-bottom:30px;
       
        }
        #form-mail{
          margin-left:0;
        }
      }
}




}

@media only screen and (max-width: 821px) {

 
  .Contact_Container{
      padding: 60px;
  }

}
@media only screen and (max-width: 600px) {
  .Contact_Info_Container{
      flex-direction: column;
      align-items: flex-start;
      margin: 0;


      .FormContactInfo{
        padding-left: 0;
      }
  
  }

  .Contact_Container{
      padding: 60px 20px;
  }

  .Contact_Column{
      margin-bottom: 30px;
     margin-left: 0;
      h1{
          margin: 40px 0;
      }
      a{
          margin: 5px 0;
      }
  }



}