.Footer_Carousel_Container{
  height:100px;
  width:100%;
  background-color:#303030;
  margin:0;
  display:flex;
  flex-direction: row;
  align-items: center;
  overflow:hidden;

  .carousel_swiper{
    display:flex;
    align-items: center;
    margin-left:25px;
    align-content: center;
  }

 
}

@media only screen and (max-width: 620px) {
  .Footer_Carousel_Container{
    .carousel_swiper{
      img{
        width:120px;
      }
      margin-left:0;
    }
  }
}
@media only screen and (max-width: 400px) {

  .Footer_Carousel_Container{
    .carousel_swiper{
      img{
        width:100px;
      }
      margin-left:0;
    }
  }
}