a {
  text-decoration: none;
  background-color: transparent;
}
.RecentPost_Container {
  display: flex;
  margin-bottom: 30px;
  .RecentPost_Image {
    margin: 0;
    height: 80px;
    width: 80px;
    object-fit: cover;
  }
  .RecentPost_Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 16px;
    .RecentPost_Title {
      color: #252525;
      line-height: 24px;
      font-weight: 600;
    }
    .RecentPost_Footer {
      .RecentPost_Footer_Brand {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: #e7ab3c;
        letter-spacing: 2px;
        line-height: 20px;
      }
      .RecentPost_Footer_Date {
        color: #b2b2b2;
        font-weight: 400;
        text-transform: capitalize;
        letter-spacing: 0;
      }
    }
  }
}
