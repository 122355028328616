@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap");

.blog__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  color: #252525;
  font-size: 36px;
  font-weight: 700;
  font-family: "Mulish", sans-serif;
  position: relative;
  text-align: center;
}
.blog__header::before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -14px;
  width: 80px;
  height: 3px;
  background: #e7ab3c;
  content: "";
  margin: 0 auto;
}

.blog__section {
  display: grid;
  gap: 30px;
  padding-top: 50px;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(360px, max-content));
  grid-gap: 16px;
  justify-content: center;
  overflow: hidden;
}

.blog__container {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 474px;
  min-width: 260px;
}

.img_container {
  display: flex;
  width: 100%;
  img {
    max-width: 100%;
  }
}

.info {
  display: flex;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  padding-top: 25px;
  font-size: 16px;
  color: #a2a1a1;
  width: 100%;
  gap: 12px;
  div {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  img {
    height: 40px;
    margin-right: -7px;
  }
}

.calendar {
  margin-left: -11px;
}
.title {
  font-family: "Mulish", sans-serif;
  color: #252525;
  font-weight: 700;
  line-height: 30px;
  font-size: 24px;
}

.description {
  font-size: 16px;
  font-family: "Mulish", sans-serif;
  color: #636363;
  font-weight: 400;
  word-break: break-all;
}
//make them display flex!!!!
@media screen and (max-width: 835px) {
  .blog__section {
    display: flex !important;
    flex-wrap: wrap;
    min-width: 80%;
    max-width: 90vw;
  }
  .blog__container {
    width: auto;
    height: auto;
    min-width: 0px;
  }
  .blog__container {
    min-width: 510px;
  }

  .img_container {
    img {
      min-width: 100%;
    }
  }
}

@media screen and (max-width: 550px) {
  .blog__section {
    max-width: 95vw;
  }
  .blog__container {
    width: auto;
    height: auto;
    min-width: 0px;
  }

  .img_container {
    min-width: 0px;
    img {
      min-width: 100%;
    }
  }
}