.map_container{
  width: 100%;
  text-align: center;
  position: relative;
  margin-top:50px;


  .contact_map{
    z-index: 0;
    height: 610px;
    width: 75%;
    padding: 0px;
    border-width: 0px;
    margin: 0px;
    touch-action: pan-x pan-y;
    position: relative;


  }

  img{
    width:50px; 
    height:50px;
    z-index: 99;
    position: absolute;
    left:50%;
    top:50%;
 

  }
}